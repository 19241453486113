import React, { useMemo } from "react";
import Media from "./gallery";
import useFetch from "../useFetch";

const Galleries = () => {
  const { data, isLoading, error } = useFetch("setup");

  const setup = useMemo(() => {
    return (
      data && data.filter((d) => !data || d.name.toLowerCase().includes("feed"))
    );
  }, [data]);

  return (
    <div className="w-100">
      <div className="container">
        <div className="w-100">
          <h1 style={{ fontWeight: 200, padding: "50px 0px" }}>
            <br />
            <br />
            IMTE LATEST UPDATE
          </h1>
          {isLoading && <div>Loading... </div>}
          {error && <div>{error}</div>}
        </div>
        <div className="row">
          {setup &&
            setup.map((data) => (
              <Media
                key={data.id}
                src={data.src}
                url={data.url}
                alt={data.alt}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Galleries;
