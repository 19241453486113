import React, { useState, useMemo } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserContext } from "./components/UserContext";
import routes from "./components/config/routes";

function App() {
  const [dataAES, setDataAES] = useState({});
  const value = useMemo(() => ({ dataAES, setDataAES }), [dataAES]);
  // console.log("context", value);
  return (
    <UserContext.Provider value={value}>
      <Router>
        <Routes>
          {routes.web.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              element={route.element}
            />
          ))}
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
