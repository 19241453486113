import React, { useContext, useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import API from "../api";
import useFetch from "../useFetch";
import UserSignup from "./userSignup";
import { loginReducer, initialLogin } from "../reducer/userReducer";
import "../assets/css/login.css";
import "../assets/css/modal.css";
import { AesDecryptor, AESEncryptor } from "../config/aesDecryptor";
import Modal from "./modal";

const Login = (props) => {
  const { setDataAES } = useContext(UserContext);
  const { data: aktif } = useFetch("activity/getImteStatus");
  const { data: period } = useFetch("period");
  const location = useLocation();
  const navigate = useNavigate();

  let today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  const [data, setData] = useState({
    email: "",
    password: "",
    isRemember: false,
    message: "",
    error: "",
    vis: true,
    token: { token: "", date: date },
  });
  const [forgot, setForgot] = useState({ vis: false });
  const [state, dispatch] = useReducer(loginReducer, initialLogin);
  useEffect(() => {
    location.state = data;
  }, [data.token]);

  const handleChange = (e) => {
    // console.log(`${[e.target.name]}`, e.target.value);
    dispatch({ type: "ONCHANGE", name: e.target.name, value: e.target.value });
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setForgot({ ...forgot, email: e.target.value });
    }

    // console.log("data login", data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await API.post("user/login", data);
      // console.log(res);
      if (res.data.message === "login successfull") {
        let aesDe = AesDecryptor(res);
        let e = {
          ...data,
          message: aesDe.message,
          verified: aesDe.verified,
          token: { date: date, token: aesDe.decryptedJson },
          modal: 1,
          vis: false,
        };
        dispatch({ type: "LOGIN", payload: e });
        const { xrf, aesdata } = AESEncryptor(e);
        sessionStorage.clear();
        localStorage.clear();
        if (state.isRemember === true) {
          localStorage.setItem("xrf", xrf);
          localStorage.setItem("token", aesdata);
          localStorage.setItem(
            "avpi",
            `${aktif.status},${e.verified},${period[0].id},${e.token.token.id}`
          );
          sessionStorage.setItem("xrf", xrf);
          sessionStorage.setItem("token", aesdata);
          sessionStorage.setItem(
            "avpi",
            `${aktif.status},${e.verified},${period[0].id},${e.token.token.id}`
          );
        } else {
          sessionStorage.setItem("xrf", xrf);
          sessionStorage.setItem("token", aesdata);
          sessionStorage.setItem(
            "avpi",
            `${aktif.status},${e.verified},${period[0].id},${e.token.token.id}`
          );
        }
        setDataAES(e);

        // console.log(res);
        // console.log(e);
        navigate("/d", { state: e });
      } else {
        throw res;
      }
    } catch (error) {
      console.log(error);
      let e = {
        ...data,
        message: "Error: " + error.data.message,
        modal: 1,
        vis: false,
      };
      dispatch({ type: "LOGIN", payload: e });
    }
  };

  return (
    <>
      {/* {console.log(state)} */}
      {state.vis ? (
        ""
      ) : (
        <Modal
          modal={!state.vis}
          handleClose={() =>
            dispatch({
              type: "ONCHANGE",
              name: "vis",
              value: true,
            })
          }
          title={
            { 1: "Message", 2: "User Signup", 3: "Request", 4: "Reset Data" }[
              state.modal
            ]
          }
          element={
            {
              1: (
                <>
                  <div style={{ fontSize: "18px" }}>{state.message}</div>{" "}
                  <div className="w-100" style={{ height: "25px" }}></div>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      dispatch({
                        type: "ONCHANGE",
                        name: "vis",
                        value: true,
                      })
                    }
                  >
                    OK
                  </button>
                </>
              ),
              2: (
                <UserSignup
                  handleClose={(e) =>
                    dispatch({ type: "ONCHANGE", name: "vis", value: true })
                  }
                />
              ),
              3: (
                <>
                  Please wait while we processing your request. <br />
                  Don't CLOSE or REFRESH this page.
                </>
              ),
              4: (
                <>
                  <div
                    className="w-100"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {forgot.data && forgot.data.otp ? (
                      <>
                        {forgot.check && forgot.check ? (
                          ""
                        ) : (
                          <>
                            <label className="sr-only">
                              Please check your email and input your OTP Code!
                            </label>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <input
                                className="form-control"
                                type="number"
                                name="number"
                                onChange={handleChange}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  state.number &&
                                  state.number === forgot.data.otp
                                    ? setForgot({ ...forgot, check: true })
                                    : setForgot({
                                        ...forgot,
                                        check: false,
                                        msg: "Incorrect OTP",
                                      });
                                }}
                              >
                                CHECK
                              </button>
                            </div>
                          </>
                        )}
                        {forgot.check && forgot.check ? (
                          ""
                        ) : (
                          <small style={{ color: "red" }}>{forgot.msg}</small>
                        )}
                        {forgot.check ? (
                          <>
                            <label className="sr-only" htmlFor="password">
                              Input New Password
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              id="inputPassword"
                              placeholder="Password"
                              required
                              onChange={(e) =>
                                setForgot({
                                  ...forgot,
                                  password: e.target.value,
                                })
                              }
                            />
                            <div
                              className="w-100"
                              style={{ height: "25px" }}
                            ></div>
                            <button
                              className="btn btn-primary btn-block"
                              onClick={async (e) => {
                                e.preventDefault();
                                console.log(forgot);
                                try {
                                  let res = await API.post(
                                    "user/resetPass",
                                    forgot
                                  );
                                  console.log(res);
                                  setForgot({
                                    ...forgot,
                                    data: res.data,
                                    vis: false,
                                  });
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              Reset Now
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      forgot.data && forgot.data.message
                    )}
                    {/* {JSON.stringify(forgot)} */}
                  </div>
                </>
              ),
            }[state.modal]
          }
        />
      )}

      <div className="form-center text-center">
        <div>
          <div className="d-none d-sm-block">
            <img
              className="mb-4"
              src="./logo512.png"
              alt=""
              width="72"
              height="72"
            />
          </div>
          <h1 className="h3 mb-3 font-weight-normal">
            {forgot.vis ? "Reset Password" : "Sign in"}
          </h1>
          <form
            className="form-signin"
            onSubmit={handleSubmit}
            style={{ textAlign: "left" }}
          >
            <label className="sr-only" htmlFor="email">
              Email address
            </label>
            <input
              className="form-control"
              type="text"
              name="email"
              id="email"
              placeholder="Email address"
              required
              autoFocus
              onChange={handleChange}
            />
            {forgot.vis ? (
              <div className="w-100" style={{ height: "25px" }}></div>
            ) : (
              <>
                <label className="sr-only" htmlFor="password">
                  Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  id="inputPassword"
                  placeholder="Password"
                  required
                  onChange={handleChange}
                />
                <div className="form-check checkbox mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isRemember"
                    onChange={() => {
                      var is = data.isRemember;
                      setData({ ...data, isRemember: !is });
                      dispatch({
                        type: "ONCHANGE",
                        name: "isRemember",
                        value: !is,
                      });
                      // console.log(data.isRemember);
                    }}
                  />
                  <label className="form-check-label"> Remember me</label>
                </div>
                <span>{data.message}</span>
              </>
            )}
            {/* {JSON.stringify(data)} */}
            {forgot.vis ? (
              <>
                <button
                  className="btn btn-primary btn-block"
                  onClick={async (e) => {
                    e.preventDefault();
                    let x = { modal: 3, vis: false };
                    dispatch({ type: "LOGIN", payload: x });
                    try {
                      let res = await API.post("user/sendPassResetOtp", forgot);
                      console.log(res.data);
                      setForgot({ ...forgot, data: res.data });
                      x = { modal: 4, vis: false };
                      dispatch({ type: "LOGIN", payload: x });
                    } catch (error) {
                      console.log(error);
                      setForgot({
                        ...forgot,
                        data: error.message + ". Please try again later.",
                      });
                      x = { modal: 4, vis: false };
                      dispatch({ type: "LOGIN", payload: x });
                    }
                  }}
                >
                  Reset
                </button>
                <button
                  className="btn btn-light btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    setForgot({ vis: false });
                  }}
                >
                  Sign In
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-primary btn-block" type="submit">
                  Sign In
                </button>
                <button
                  className="btn btn-light btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    setForgot({ vis: true });
                  }}
                >
                  Forgot Password
                </button>
              </>
            )}
            <div className="w-100" style={{ height: "25px" }}></div>
            <hr />
            <div style={{ textAlign: "center" }}>
              <span>Don't have an account?</span>
              <br />
              <div
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  let d = { vis: false, modal: 2 };
                  dispatch({ type: "LOGIN", payload: d });
                }}
                onMouseEnter={(e) => (e.target.className = "btn btn-secondary")}
                onMouseLeave={(e) => (e.target.className = "text-primary")}
              >
                Sign Up Here
              </div>
            </div>
            <hr />
            <p className="mt-5 mb-3 text-muted">&copy; 2021-2022</p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
