import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useWindow from "../useWindow";
import usePost from "../usePost";
import "../assets/css/dashHis.css";
import useAES from "../useAES";

const DashHis = () => {
  const { width } = useWindow();
  const { data: aes } = useAES(
    sessionStorage.getItem("token") || localStorage.getItem("token")
  );
  useEffect(() => {
    aes && setData(aes.token.token);
  }, [aes]);
  const [locate] = useState("");
  const [data, setData] = useState("");
  let avpi = (
    sessionStorage.getItem("avpi") || localStorage.getItem("avpi")
  ).split(",");
  let ids = parseInt(avpi[3]);
  const { data: enroll } = usePost("enroll/show", {
    tab_user_id: ids,
  });
  return (
    <>
      {console.log(data)}
      <div className="w-100" style={{ background: "#eeeeee" }}>
        <div
          className="navbar navbar-default align-items-start"
          style={{ margin: "0px", padding: "0px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className="navbar-header">
              <div className="page-title" style={{ color: "#212529" }}>
                HISTORY
              </div>
            </div>
            {width > 450 ? (
              ""
            ) : (
              <Link
                to={"/login"}
                state={locate}
                className="nav-link"
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  window.location.assign("/login");
                }}
              >
                <button type="button" className="btn">
                  <i className="bi bi-door-open" aria-hidden="true"></i>
                </button>
              </Link>
            )}
          </div>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="row" style={{ margin: "0px" }}>
            {enroll && (enroll === null || enroll.length === 0) ? (
              <div>"Anda belum pernah terdaftar dalam ujian IMTE"</div>
            ) : (
              enroll &&
              enroll.map((d) => (
                <div className="col-md-6" key={d.id}>
                  <Link
                    to={`/d/qrcam/${d.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div
                      className="history__container"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                        background: {
                          Piano:
                            "url(../assets/img/icopiano.png) no-repeat right bottom",
                          Drum:
                            "url(../assets/img/icodrum.png) no-repeat right bottom",
                          "Acoustic Guitar":
                            "url(../assets/img/icoguitar.png) no-repeat right bottom",
                          Vocal:
                            "url(../assets/img/icovocal.png) no-repeat right bottom",
                          Violin:
                            "url(../assets/img/icoviolin.png) no-repeat right bottom",
                          Flute:
                            "url(../assets/img/icoflute.png) no-repeat right bottom",
                          Saxophone:
                            "url(../assets/img/icosaxo.png) no-repeat right bottom",
                          Bass:
                            "url(../assets/img/icoguitar.png) no-repeat right bottom",
                          "Electric Guitar":
                            "url(../assets/img/icoguitar.png) no-repeat right bottom",
                        }[d.major.major],
                        backgroundColor: {
                          1: "rgba(255, 0, 0, 0.10)",
                          2: "rgba(255, 255, 0, 0.10)",
                          3: "rgba(0, 124, 255, 0.10)",
                        }[d.enroll_status],
                      }}
                    >
                      <div className="his__card">
                        {d.period.period_name}
                        <br />
                        {d.major.major} - {d.grade.grade}
                        <br />
                        {`${d.teacher.first_name} ${
                          d.teacher.last_name ? d.teacher.last_name : ""
                        }`}
                        <br />
                        {d.rank} <br />
                        {d.result && d.result.gpa}
                        <br />
                        {
                          {
                            0: "Unverified", //unverified
                            1: "Registered", // verified
                            2: "Test Taken", // test taken
                            3: "Result Out", // result out
                          }[d.enroll_status]
                        }
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashHis;
