import theVariable from "../config/theVariable";
import CryptoJS from "crypto-js";

const AesDecryptor = (data) => {
  let passphrase = theVariable.AESDecryption;
  // alert(JSON.stringify(data.data.message));
  // response data -> base64 Decrypt -> json
  let encrypted_json_string = CryptoJS.enc.Base64.parse(
    data.data.data
  ).toString(CryptoJS.enc.Utf8);
  let obj_json = JSON.parse(encrypted_json_string);

  // data from json
  let encrypted = obj_json.ciphertext;
  let salt = CryptoJS.enc.Hex.parse(obj_json.salt);
  let iv = CryptoJS.enc.Hex.parse(obj_json.iv);
  let key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: 64 / 8,
    iterations: 999,
  });

  // decrypted data
  let decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
  let decryptedJson = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  let r = {
    decryptedJson,
    verified: data.data.verified,
    message: data.data.message,
  };
  // console.log(res);
  return r;
};

const AESEncryptor = (data) => {
  let aesdata = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    theVariable.AESEncryption
  ).toString();
  let xrf = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    theVariable.AESEncryption
  ).toString();

  return { xrf, aesdata };
};

export { AesDecryptor, AESEncryptor };
