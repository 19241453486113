import React, { Component } from "react";
import "../assets/css/gallery.css";

class Media extends Component {
  render() {
    return (
      <div
        className="col-md-4 feed__filter"
        style={{
          padding: "15px",
        }}
      >
        <a href={this.props.url} rel="noopener noreferrer" target="_blank">
          <img
            className="feed__box"
            src={this.props.src}
            alt={this.props.alt}
            crossOrigin="instagram.com"
            decoding="auto"
            style={{ width: "100%" }}
          ></img>
        </a>
      </div>
    );
  }
}

export default Media;
