import React from "react";

const EnrollUnit = ({ data }) => {
  return (
    <div className="col-md-6 enroll__unitBox">
      <h1 className="enroll__unit">
        {data.unit_name}
        <br />
        <small style={{ fontSize: "9px" }}>{data.city}</small>
      </h1>
      <h1 className="enroll__date">
        {data.mulai === data.selesai && data.selesai}
        {data.mulai !== data.selesai && data.mulai + " - " + data.selesai}
      </h1>
    </div>
  );
};

export default EnrollUnit;
