import React, { useMemo } from "react";
import useFetch from "../useFetch";

const Hero = () => {
  const { data, isLoading, error } = useFetch("setup");

  const setup = useMemo(() => {
    return data && data.filter((d) => !data || d.name === "hero_image");
  }, [data]);

  return (
    <section style={{ margin: "auto", padding: "0px" }}>
      <div className="row" style={{ margin: "auto", padding: "0px" }}>
        <div
          style={{
            background: "#222222",
            margin: "auto",
            padding: "0px",
            minHeight: "",
            width: "100%",
          }}
        >
          {isLoading && <div>Loading... </div>}
          {error && <div>{error}</div>}
          {setup &&
            setup.map((data) => (
              <video
                key={data.id}
                autoPlay={true}
                loop={true}
                controls={false}
                playsInline
                muted
                style={{ margin: "auto", width: "100%" }}
              >
                <source src={data.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
    </section>
  );
};

export default Hero;
