import React, { useState, useContext } from "react";
import { UserContext } from "../UserContext";
import useFetch from "../useFetch";
import Period from "../period";
import Instrument from "../instrument";
import Grade from "../grade";
import API from "../api";

const EnrollNow = (props) => {
  const { dataAES } = useContext(UserContext);
  let avpi = (
    sessionStorage.getItem("avpi") || localStorage.getItem("avpi")
  ).split(",");
  let prd = parseInt(avpi[2]);
  let ids = parseInt(avpi[3]);
  const [data, setData] = useState({
    id: ids,
    grade: "",
    major: "",
    period: prd,
    teacher_id: "",
    unit: dataAES && dataAES.token.token.tab_unit_id.toString(),
    payment: "",
  });
  const { data: unit } = useFetch("unit");
  const { data: periods } = useFetch("period");
  const { data: teachers } = useFetch("teacher");
  const { data: major } = useFetch("major");
  const { data: grades } = useFetch("grade");
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(data);
    // progress upload
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded} byte of ${total} byte | ${percent}%`);

        if (percent < 100) {
          setData({ ...data, percentage: percent });
        }
      },
    };

    let dataEnroll = new FormData();
    dataEnroll.append("id", data.id);
    dataEnroll.append("grade", data.grade);
    dataEnroll.append("major", data.major);
    dataEnroll.append("period", data.period);
    dataEnroll.append("grade", data.grade);
    dataEnroll.append("teacher_id", data.teacher_id);
    dataEnroll.append("unit", data.unit);
    dataEnroll.append("payment", data.payment[0]);

    if (data.grade && data.major && data.teacher_id) {
      // IMTE Register Enroll
      API.post(`enroll/store`, dataEnroll, options)
        .then((res) => {
          // console.log(res);
          setData({
            ...data,
            modal: res.data.enroll,
            message: "your data is submitted",
          });
          alert(res.data.message);
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          alert(
            "There is some error. Please contact imte Support for futher information"
          );
        });
    } else {
      alert("Anda belum melengkapi data!");
    }
  };
  const handleClose = (e) => {
    props.handleClose(e);
  };
  const handleUpload = (e) => {
    // console.log(e.target.files[0]);
    let files = e.target.files;
    if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
      if (files[0].size > 2000000) {
        alert("File terlalu besar! Silahkan pilih file yang lebih kecil.");
      } else {
        setData({ ...data, payment: files });
      }
    } else {
      alert("Only PNG, JPG or JPEG are allowed.");
    }
  };
  const progress = {
    backgroundColor: "DodgerBlue",
    width: data.percentage,
    height: "3px",
    padding: "0px",
  };
  return (
    <>
      {console.log(data)}
      <form
        className="col-md-12"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          margin: "0",
          padding: "0",
        }}
        onSubmit={handleSubmit}
      >
        <div className="col-md-12" style={{ display: "none" }}>
          {periods &&
            periods.map((periods) => (
              <Period
                id={periods.id}
                key={`${periods.id} period`}
                name="period"
                value={periods.id}
                date={periods.exam_date}
                onChange={handleChange}
                checked={data.period === String(periods.id)}
              />
            ))}
        </div>
        <div className="col-md-12">
          <label>
            <small>Teacher Name</small>
            <small style={{ color: "red" }}>*</small>
          </label>
          <select
            className="form-control"
            name="teacher_id"
            id="data"
            onChange={handleChange}
            required={true}
          >
            <option className="m2" key="0" value="-">
              -
            </option>
            {teachers &&
              teachers
                .filter(
                  (teachers) => String(teachers.tab_unit_id) === data.unit
                )
                .sort((a, b) => a.first_name > b.first_name)
                .map((teachers) => (
                  <option
                    className="m2"
                    key={`${teachers.id} teacher`}
                    value={teachers.id}
                  >
                    {`${teachers.first_name} ${teachers.last_name}`}
                  </option>
                ))}
          </select>
        </div>
        <div className="col-md-12" style={{ display: "none" }}>
          <label className="">
            <small>Unit</small>
          </label>
          <select className="form-control" name="unit" onChange={handleChange}>
            {unit &&
              unit.map((e, i) => (
                <option key={i} value={e.id}>
                  {e.unit_name}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-6">
          <label className="">
            <small>Instrument</small>
            <small style={{ color: "red" }}>*</small>
          </label>
          {major &&
            major.map((instruments) => (
              <Instrument
                id={instruments.id}
                key={`${instruments.id} instrument`}
                name="major"
                value={instruments.major}
                onChange={handleChange}
                checked={data.major === String(instruments.id)}
              />
            ))}
        </div>
        <div className="col-md-6">
          <label className="">
            <small>Grade</small>
            <small style={{ color: "red" }}>*</small>
          </label>
          {data.major === "1"
            ? grades &&
              grades
                .filter((grades) => grades.id !== 7 && grades.id !== 8)
                .map((grades) => (
                  <Grade
                    id={grades.id}
                    key={`${grades.id} grade`}
                    name="grade"
                    value={grades.grade}
                    onChange={handleChange}
                    checked={data.grade === String(grades.id)}
                  />
                ))
            : grades &&
              grades
                .filter((grades) => grades.id < 12)
                .map((grades) => (
                  <Grade
                    id={grades.id}
                    key={`${grades.id} grade`}
                    name="grade"
                    value={grades.grade}
                    onChange={handleChange}
                    checked={data.grade === String(grades.id)}
                  />
                ))}
        </div>
        <div className="col-md-12">
          <label>
            <small>Payment Receipt Upload</small>
          </label>
          <div className="">
            {data.percentage > 0 && (
              <div>FILE UPLOAD - {data.percentage}% </div>
            )}
            <input
              className="form-control"
              type="file"
              onChange={handleUpload}
              name="payment"
            />
            <div className="col-md-12" style={progress}></div>
            <small style={{ color: data.color }}>{data.message}</small>
          </div>
        </div>
        <div className="w-100" style={{ height: "25px" }}></div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{
            textAlign: "center",
            height: "auto",
          }}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default EnrollNow;
