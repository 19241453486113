var today = new Date(),
  date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

export const initialLogin = {
  email: "",
  password: "",
  isRemember: false,
  message: "",
  error: "",
  vis: true,
  token: { token: "", date: date },
};

export const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      let data = { ...action.payload };
      // console.log(data);
      return data;

    case "ONCHANGE":
      let nam = action.name;
      let val = action.value;
      // console.log(nam, val);
      return { ...state, [nam]: val };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
