import React from "react";

const Grade = (props) => {
  // console.log(props);

  return (
    <div className="">
      <input
        className="m-1"
        type="radio"
        id={props.id}
        name={props.name}
        value={props.id}
        onChange={props.onChange}
        checked={props.checked}
      />
      <label className="m-1 form-check-label" htmlFor={props.value}>
        {props.value}
      </label>
    </div>
  );
};

export default Grade;
