import React, { useState, useContext, useReducer } from "react";
import { Link } from "react-router-dom";
import useWindow from "../useWindow";
import API from "../api";
import { UserContext } from "../UserContext";
import { Avt } from "../config/avatar";
import { profileReducer } from "../reducer/profileReducer";

const Profile = () => {
  const { width } = useWindow();
  const { dataAES, setDataAES } = useContext(UserContext);
  const [data, setData] = useState(dataAES.token ? dataAES.token.token : "");
  const [file, setFile] = useState("");
  const [message, setMessage] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [edit, setEdit] = useState(false);
  const [state, dispatch] = useReducer(profileReducer, data);
  const handleChange = (e) => {
    const nam = e.target.value;
    // console.log({ [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      const a = nam.split(" ");
      let first = !a[1] ? a[0] : a[0] + " " + a[1];
      let last = !a.slice(1) ? "" : a.slice(2).join(" ");
      dispatch({
        type: "ONCHANGEALL",
        payload: { first_name: first, last_name: last },
      });
      setData({ ...data, first_name: first, last_name: last });
    } else if (e.target.name === "email") {
      dispatch({
        type: "ONCHANGEALL",
        payload: { cred: { ...state.cred, email: e.target.value } },
      });
    } else {
      dispatch({
        type: "ONCHANGE",
        name: [e.target.name],
        value: e.target.value,
      });
      setData({ ...data, [e.target.name]: e.target.value });
    }
    // console.log("data", data);
  };
  const handleFileUpload = async ({ target: { files } }) => {
    let dat = new FormData();
    dat.append("photo", files[0]);
    dat.append("id", data.id);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded} byte of ${total} byte | ${percent}%`);

        if (percent < 100) {
          setPercentage(percent);
        } else if (percent === 100) {
          setPercentage("Uploaded");
        }
      },
    };
    try {
      const res = await API.post("user/photo", dat, options);
      setFile(res.data.file);
      setMessage(res.data.message);
      alert(res.data.message);
      // console.log(file);
      setPercentage(100);
      setData({ ...data, photo_location: res.data.file });
    } catch (error) {
      console.log(error);
    }

    // console.log("Data", data);
    // console.log("AES", dataAES);
    // await setDataAES(data);
  };
  return (
    <>
      <div className="w-100" style={{ overflowY: "auto" }}>
        <div
          className="navbar navbar-default align-items-start"
          style={{ margin: "0px", padding: "0px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className="navbar-header">
              <div className="page-title" style={{ color: "#212529" }}>
                PROFILE
              </div>
            </div>
            {width > 450 ? (
              ""
            ) : (
              <Link
                to={"/login"}
                className="nav-link"
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  window.location.assign("/login");
                }}
              >
                <button type="button" className="btn">
                  <i className="bi bi-door-open" aria-hidden="true"></i>
                </button>
              </Link>
            )}
          </div>
        </div>
        <hr />
        <div className="col-md-12">
          <div className="w-100" style={{ padding: "25px" }}>
            <div>
              <h2>Avatar</h2>
            </div>
            <div className="w-100" style={{ height: "25px" }}></div>
            <div className="row">
              <div className="col-md-4 col-4" style={{ maxWidth: "250px" }}>
                {data && (
                  <div
                    className="dashProfile"
                    style={{
                      background: data.photo_location
                        ? `url(../profile_pic/${
                            !file ? data.photo_location : file
                          }) center center /cover`
                        : data.gender === ""
                        ? `url(${
                            Avt.a[Math.floor(Math.random() * Avt.a.length)]
                          }) center center /cover `
                        : data.gender === "L"
                        ? `url(${
                            Avt.m[Math.floor(Math.random() * Avt.m.length)]
                          }) center center /cover `
                        : `url(${
                            Avt.f[Math.floor(Math.random() * Avt.f.length)]
                          }) center center /cover `,
                      width: "100px",
                      height: "100px",
                    }}
                  ></div>
                )}
              </div>
              <div className="col-md-8 col-7">
                <p>
                  Your avatar is one of the defining parts of your profile.
                  It'll help Teachers and others establish an impression of you.
                </p>
                <label className="btn btn-primary">
                  Edit Avatar
                  <input
                    type="file"
                    name="profile_pic"
                    id="profile_pic"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </label>
                {percentage === 0
                  ? ""
                  : percentage === 100
                  ? message
                  : percentage}
              </div>
            </div>
          </div>
          <div className="w-100" style={{ padding: "25px" }}>
            <div>
              <h2>Personal Information</h2>
            </div>
            <div className="row">
              {data && (
                <div className="col-md-12">
                  {/* ## fullname & gender  */}
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <div className="w-100" style={{ height: "25px" }}></div>
                    <p>
                      <b>Student Details</b>
                    </p>
                    <div className="form-row">
                      <div
                        className="row col-md-6 "
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="name" className="col-md-4 col-4">
                          Fullname
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Fullname"
                            value={
                              !edit
                                ? `${state.first_name} ${state.last_name}`
                                : state.fullname
                            }
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="gender" className="col-md-4 col-4">
                          Gender
                        </label>
                        <div className="col-md-8 col-8">
                          <div
                            className="row col-md-12 col-12"
                            style={{ padding: "0px", margin: "0px" }}
                          >
                            <div
                              className="form-check col-md-6 col-12"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "38px",
                              }}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                value="L"
                                onChange={handleChange}
                                checked={state.gender === "L"}
                                disabled={!edit}
                              />

                              <label
                                className="form-check-label"
                                htmlFor="L"
                                style={{ paddingLeft: "15px" }}
                              >
                                Laki-Laki
                              </label>
                            </div>
                            <div
                              className="form-check col-md-6 col-12"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "38px",
                              }}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                value="P"
                                onChange={handleChange}
                                checked={state.gender === "P"}
                                disabled={!edit}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="P"
                                style={{ paddingLeft: "15px" }}
                              >
                                Perempuan
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="name" className="col-md-4 col-4">
                          Place
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="place"
                            name="place"
                            placeholder="Place of birth"
                            defaultValue={`${
                              state.place === null ? "" : state.place
                            }`}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="name" className="col-md-4 col-4">
                          Birth Date
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="date"
                            className="form-control"
                            id="date"
                            name="date_of_birth"
                            defaultValue={`${data.date_of_birth}`}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* mobile and email addresses  */}
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <div className="form-row">
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label
                          htmlFor="mobile"
                          className="col-md-4 col-4"
                          onMouseEnter={(e) => {
                            e.target.children[0].innerText = data.mobile_verified_at
                              ? "verified"
                              : "unverified";
                          }}
                          onMouseLeave={(e) => {
                            e.target.children[0].innerText = "";
                          }}
                        >
                          Mobile{" "}
                          {data.mobile_verified_at ? (
                            <i
                              className="bi bi-check-all"
                              style={{
                                // background: "green",
                                fontWeight: "100",
                                // borderRadius: "15px",
                                color: "green",
                                // fontSize: "10px",
                                cursor: "pointer",
                                padding: "3px 6px",
                              }}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-check"
                              style={{
                                // background: "yellow",
                                fontWeight: "100",
                                // borderRadius: "15px",
                                color: "grey",
                                // fontSize: "10px",
                                padding: "3px 6px",
                              }}
                              onClick={() => {
                                let dat = new FormData();
                                dat.append("id", state.id);
                                API.post("user/mobileotp", dat).then((res) => {
                                  // console.log(res);
                                  setMessage(res.data.message);
                                });
                              }}
                            ></i>
                          )}
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            className="form-control"
                            type="tel"
                            id="mobile"
                            name="mobile"
                            placeholder="mobile"
                            value={state.mobile === null ? "" : state.mobile}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label
                          htmlFor="email"
                          className="col-md-4 col-4"
                          onMouseEnter={(e) => {
                            e.target.children[0].innerText = data.cred
                              .email_verified_at
                              ? "verified"
                              : "unverified";
                          }}
                          onMouseLeave={(e) => {
                            e.target.children[0].innerText = "";
                          }}
                        >
                          Email{" "}
                          {data.cred && data.cred.email_verified_at ? (
                            <i
                              className="bi bi-check-all"
                              style={{
                                // background: "green",
                                fontWeight: "100",
                                // borderRadius: "15px",
                                color: "green",
                                // fontSize: "10px",
                                padding: "3px 6px",
                              }}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-check"
                              style={{
                                // background: "yellow",
                                fontWeight: "100",
                                // borderRadius: "15px",
                                color: "grey",
                                // fontSize: "10px",
                                padding: "3px 6px",
                              }}
                              onClick={() => {
                                let dat = new FormData();
                                dat.append("id", state.id);
                                API.post("", dat).then((res) => {
                                  // console.log(res);
                                  setMessage(res.data.message);
                                  alert(message);
                                });
                              }}
                            ></i>
                          )}
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="email"
                            value={`${state.cred && state.cred.email}`}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ## Address & City  */}
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <div className="form-row">
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="address" className="col-md-4 col-4">
                          Address
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            placeholder="address"
                            defaultValue={`${
                              state.address === null ? "" : state.address
                            }`}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="city" className="col-md-4 col-4">
                          City
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            placeholder="city"
                            defaultValue={`${
                              state.city === null ? "" : state.city
                            }`}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ## Wali & no Wali  */}
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <div className="w-100" style={{ height: "25px" }}></div>
                    <p>
                      <b>Guardian Details</b>
                    </p>
                    <div className="form-row">
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="wali" className="col-md-4 col-4">
                          Name
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="wali"
                            name="wali"
                            placeholder="Guardian's Name"
                            defaultValue={`${
                              state.wali === null ? "" : state.wali
                            }`}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                      <div
                        className="row col-md-6"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <label htmlFor="no_wali" className="col-md-4 col-4">
                          Mobile
                        </label>
                        <div className="col-md-8 col-8">
                          <input
                            type="text"
                            className="form-control"
                            id="no_wali"
                            name="no_wali"
                            placeholder="Guardian's Mobile"
                            defaultValue={`${
                              state.no_wali === null ? "" : state.no_wali
                            }`}
                            onChange={handleChange}
                            readOnly={!edit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-100" style={{ height: "25px" }}></div>

                  <div>
                    {!edit ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setEdit(true);
                        }}
                      >
                        Edit
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={async () => {
                            let dat = dataAES;
                            dat.token.token = state;
                            console.log(dat);
                            setDataAES(dat);
                            try {
                              const res = await API.put(
                                `user/update/${state.id}`,
                                data
                              );
                              // await console.log(res);
                              alert(
                                `${res.data.message}
                               \nSilahkan login kembali.`
                              );
                              setEdit(false);
                              console.log(dataAES);
                              localStorage.clear();
                              sessionStorage.clear();
                              window.location.assign("/login");
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          Simpan
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => setEdit(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="w-100" style={{ height: "100px" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
