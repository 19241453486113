import f1 from "../assets/img/avatar/F/f1.gif";
import f2 from "../assets/img/avatar/F/f2.gif";
import f3 from "../assets/img/avatar/F/f3.gif";
import f4 from "../assets/img/avatar/F/f4.gif";
import f5 from "../assets/img/avatar/F/f5.gif";
import f6 from "../assets/img/avatar/F/f6.gif";
import m1 from "../assets/img/avatar/M/m1.gif";
import m2 from "../assets/img/avatar/M/m2.gif";
import m3 from "../assets/img/avatar/M/m3.gif";
import m4 from "../assets/img/avatar/M/m4.gif";
import m5 from "../assets/img/avatar/M/m5.gif";
import m6 from "../assets/img/avatar/M/m6.gif";

export const Avt = {
  all: [m1, m2, m3, m4, m5, m6, f1, f2, f3, f4, f5, f6],
  m: [m1, m2, m3, m4, m5, m6],
  f: [f1, f2, f3, f4, f5, f6],
};
