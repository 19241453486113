import React, { useState, useReducer, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import API from "../api";
import useFetch from "../useFetch";
import { loginReducer, initialLogin } from "../reducer/userReducer";
import { AesDecryptor, AESEncryptor } from "../config/aesDecryptor";
import Modal from "./modal";
import preLoader from "../assets/img/pulse.gif";

const UserSignup = (props) => {
  const [vis, setVis] = useState({ modal: false });
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    password: "",
    confirm: "",
    unit: "1",
  });
  const navigate = useNavigate();
  const { setDataAES } = useContext(UserContext);
  const { data: unit } = useFetch("unit");
  const { data: aktif } = useFetch("activity/getImteStatus");
  const { data: period } = useFetch("period");
  const [state, dispatch] = useReducer(loginReducer, initialLogin);

  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  const handleChange = (e) => {
    dispatch({ type: "ONCHANGE", name: e.target.name, value: e.target.value });
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data);
    setVis({ ...vis, modal: true });
    dispatch({ type: "ONCHANGE", name: "modal", value: 1 });
    let errMsg;
    try {
      const res = await API.post(`user/register`, data);
      await API.post("user/sendVerifEmail", res.data);
    } catch (err) {
      dispatch({ type: "ONCHANGE", name: "modal", value: 2 });
      let req = JSON.parse(err.request.response);
      // console.log(err);
      // console.log(err.request.response);
      if (err.name === "AbortError") {
        dispatch({
          type: "ONCHANGE",
          name: "message",
          value: "Fetch data aborted",
        });
        // alert("fetch data aborted");
      } else if (err.name === "AxiosError") {
        errMsg =
          err.message +
          "\n\n" +
          req.message +
          "\n" +
          JSON.stringify(req.errors);
        dispatch({
          type: "ONCHANGE",
          name: "message",
          value: errMsg,
        });
        // alert(
        //   err.message + "\n\n" + req.message + "\n" + JSON.stringify(req.errors)
        // );
      }
    } finally {
      try {
        let res = await API.post("user/login", data);
        // console.log(res);
        if (res.data.message === "login successfull") {
          let aesDe = AesDecryptor(res);
          let e = {
            ...data,
            message: aesDe.message,
            verified: aesDe.verified,
            token: { date: date, token: aesDe.decryptedJson },
            modal: 1,
            vis: false,
          };
          dispatch({ type: "LOGIN", payload: e });
          const { xrf, aesdata } = AESEncryptor(e);
          sessionStorage.clear();
          localStorage.clear();
          if (state.isRemember === true) {
            localStorage.setItem("xrf", xrf);
            localStorage.setItem("token", aesdata);
            localStorage.setItem(
              "avpi",
              `${aktif.status},${e.verified},${period[0].id},${e.token.token.id}`
            );
            sessionStorage.setItem("xrf", xrf);
            sessionStorage.setItem("token", aesdata);
            sessionStorage.setItem(
              "avpi",
              `${aktif.status},${e.verified},${period[0].id},${e.token.token.id}`
            );
          } else {
            sessionStorage.setItem("xrf", xrf);
            sessionStorage.setItem("token", aesdata);
            sessionStorage.setItem(
              "avpi",
              `${aktif.status},${e.verified},${period[0].id},${e.token.token.id}`
            );
          }
          setDataAES(e);

          // console.log(res);
          // console.log(e);
          navigate("/d", { state: e });
        } else {
          throw res;
        }
      } catch (error) {
        console.log(error);
        let e = {
          ...data,
          message: "Error: \n" + errMsg + "\n" + error.data.message,
          modal: 3,
          vis: false,
        };
        dispatch({ type: "LOGIN", payload: e });
      }
    }
  };
  const handleClose = (e) => {
    setData({
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      password: "",
      confirm: "",
      unit: "",
    });
    props.handleClose(e);
  };
  return (
    <>
      {vis.modal ? (
        <Modal
          modal={vis.modal}
          title={
            { 1: "Contacting Server", 2: "Signup", 3: "Login" }[state.modal]
          }
          element={
            {
              1: (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <img src={preLoader} alt="pre loader" width="200px" />
                    <br />
                    Please wait while your data is inputed.
                  </div>
                </>
              ),
              2: (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {state.message}
                  </div>
                </>
              ),
              3: (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {state.message}
                  </div>
                </>
              ),
            }[state.modal]
          }
          handleClose={() => setVis({ modal: false })}
        />
      ) : (
        ""
      )}

      <form
        className="col-md-12"
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: "0",
          padding: "0",
        }}
        onSubmit={handleSubmit}
      >
        <div className="col-md-12 col-12">
          <label className="">
            <small>Unit</small>
          </label>
          <select className="form-control" name="unit" onChange={handleChange}>
            <option value="0">Pilih Unit</option>
            {unit &&
              unit
                .filter((f) => f.id !== 15)
                .map((e, i) => (
                  <option key={i} value={e.id}>
                    {e.unit_name}
                  </option>
                ))}
          </select>
        </div>

        <div className="col-md-6 col-12">
          <label className="">
            <small>First Name</small>
          </label>
          <input
            required={true}
            className="form-control"
            type="text"
            name="first_name"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12">
          <label className="">
            <small>Last Name</small>
          </label>
          <input
            required={true}
            className="form-control"
            type="text"
            name="last_name"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12">
          <label className="">
            <small>E-Mail</small>
          </label>
          <input
            required={true}
            className="form-control"
            type="email"
            name="email"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12">
          <label className="">
            <small>Mobile</small>
          </label>
          <input
            required={true}
            className="form-control"
            type="tel"
            name="mobile"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12">
          <label className="">
            <small>
              Password{" "}
              <i style={{ color: "red" }}>
                {data.password.length < 8 ? "  | minimum 8 characters" : ""}
              </i>
            </small>
          </label>
          <input
            required={true}
            className="form-control"
            type="password"
            name="password"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 col-12">
          <label className="">
            <small>
              Confirm Password
              <i style={{ color: "red" }}>
                {data.password !== data.confirm ? "  | data tidak sama" : ""}
              </i>
            </small>
          </label>
          <input
            required={true}
            className="form-control"
            type="password"
            name="confirm"
            onChange={handleChange}
          />
        </div>
        <div className="w-100" style={{ height: "25px" }}></div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{
            textAlign: "center",
            height: "auto",
          }}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default UserSignup;
