const useDate = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const today = new Date(),
    DD = ("0" + today.getDate()).slice(-2),
    MM = ("0" + (today.getMonth() + 1)).slice(-2),
    MMM = monthShort[today.getMonth()],
    MMMM = monthNames[today.getMonth()],
    YY = today.getFullYear(),
    HH = today.getHours(),
    mm = today.getMinutes(),
    ss = today.getSeconds(),
    now = YY + "-" + MM + "-" + DD + " " + HH + ":" + mm + ":" + ss;
  return { monthShort, monthNames, now, DD, MM, MMM, MMMM, YY, HH, mm, ss };
};

export default useDate;
