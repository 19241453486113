import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import "../assets/css/enroll.css";
import EnrollUnits from "./enrollUnits";
import useFetch from "../useFetch";

const Enroll = () => {
  const { data, isLoading, error } = useFetch("period");
  const { data: setup } = useFetch("setup");

  const setup1 = useMemo(() => {
    return setup && setup.filter((d) => !setup || d.name === "enroll__bg");
  }, [setup]);

  return (
    <>
      {/* Enroll */}
      <section
        className="enroll__bg"
        style={{
          color: "white",
          background: `url(${
            setup1 && setup1.map((d) => d.src)
          }) center center /cover fixed`,
        }}
      >
        {isLoading && <div>Loading... </div>}
        {error && <div>{error}</div>}
        <div className="container ">
          <div className="w-100" style={{ height: "100px" }}></div>
          <div className="row col-md-12">
            <div className="col-md-6">
              <strong>
                <h1 className="enroll__text">
                  OPEN
                  <br />
                  REGISTRATION
                  <br />
                  ___
                  <br />
                  <br />
                </h1>
              </strong>
              <strong>
                <h1 className="enroll__subtext">
                  {new Date().getMonth() <= 3 || new Date().getMonth() >= 8 ? (
                    <span>
                      1<sup>st</sup>
                    </span>
                  ) : (
                    <span>
                      2<sup>nd</sup>
                    </span>
                  )}{" "}
                  PERIOD {new Date().getFullYear()}
                  <br />
                  <br />
                </h1>
              </strong>
              <h1>REGISTRATION DATE:</h1>
              <h2 style={{ fontWeight: "300" }}>
                {data &&
                  data.map((data) => (
                    <div key={data.id} style={{ textTransform: "uppercase" }}>
                      {data.mulai} - {data.selesai}
                    </div>
                  ))}
                <br />
                <br />
                <br />
              </h2>
              <Link to={"./login"}>
                <div
                  className="gradred btnred"
                  style={{
                    fontSize: "16px",
                    borderRadius: "25px",
                    border: "none",
                    margin: "0px",
                  }}
                >
                  ENROLL NOW
                </div>
              </Link>
              <div className="w-100" style={{ height: "50px" }}></div>
            </div>
            <div className="col-md-6">
              <strong>
                <h1 className="enroll__text">
                  EXAM
                  <br />
                  DATES
                  <br />
                  ___
                  <br />
                  <br />
                </h1>
              </strong>
              <EnrollUnits />
            </div>
          </div>
          <div className="w-100" style={{ height: "100px" }}></div>
        </div>
        <div style={{ clear: "both" }}></div>
      </section>
    </>
  );
};

export default Enroll;
