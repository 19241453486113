export const profileReducer = (state, action) => {
  switch (action.type) {
    case "ONCHANGEALL":
      let data = { ...state, ...action.payload };
      //   console.log(data);
      return data;

    case "ONCHANGE":
      let nam = action.name;
      let val = action.value;
      //   console.log(nam, val);
      return { ...state, [nam]: val };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
