import React, { Component } from "react";
// import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import QRReader from "react-qr-reader";
import API from "../api";
// import QRScan from "./scan";
import DashHis from "../dashboard/dashHis";

class QRCam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanRes: "",
      camChange: true,
      enroll: "",
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    // this.setState(
    //   { enroll: this.props.match.params.id },
    //   console.log("enroll id = ", this.state.enroll)
    // );
    this.fetchData(id);

    console.log(this.props);
  }

  fetchData = (id) => {
    console.log("fetch data", id);
    this.setState({ enroll: id });
  };

  getDataAES = () => {
    var token =
      sessionStorage.getItem("token") || localStorage.getItem("token");
    var bytes = CryptoJS.AES.decrypt(token, "imte.education");
    var dt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    API.get(`enroll/show?tab_user_id=${dt && dt.id}`);
  };

  // getEnroll = () => {
  //   API.get(`enroll/show?tab_user_id=${dataAES ? dataAES.id : data.id}`);
  // };
  handleErrorCam = (e) => {
    console.log(e);
  };
  handleScanCam = (res) => {
    if (res) {
      var url = CryptoJS.enc.Base64.parse(res).toString(CryptoJS.enc.Utf8);
      // console.log(res);
      this.setState({ scanRes: url });
      API.get(
        `https://adm.imte.education/api/activity/changeStatus/${this.state.scanRes}/${this.state.enroll}`
      ).then((r) => alert("Activity Updated!"));
      // window.location("/d");
    }
  };

  render() {
    return (
      <>
        {" "}
        {this.state.enroll ? (
          <div className="w-100">
            <div
              className="navbar navbar-default align-items-start"
              style={{ margin: "0px", padding: "0px" }}
            >
              <div className="container">
                <div className="navbar-header">
                  <div className="page-title" style={{ color: "#212529" }}>
                    ACTIVITY
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-md-12">
              <small>
                On IOS 11 it is only supported on Safari and not on Chrome or
                Firefox due to Apple Developer Policy.
              </small>

              <QRReader
                delay={300}
                onError={this.handleErrorCam}
                onScan={this.handleScanCam}
                facingMode={this.state.camChange ? "environment" : "user"}
              />
              <button
                onClick={() => {
                  this.setState({ camChange: !this.state.camChange });
                }}
              >
                <i className="glyphicon glyphicon-retweet"></i>
              </button>
              {/* <h3>QR Code Result : {this.state.scanRes}</h3> */}
              <hr />
              {/* <QRScan /> */}
            </div>
          </div>
        ) : (
          <>
            <div className="navbar-header">
              <div className="page-title" style={{ color: "#212529" }}>
                CHOOSE YOUR ENROLL
              </div>
              <hr />
              <DashHis />
            </div>
          </>
        )}
      </>
    );
  }
}

export default QRCam;
