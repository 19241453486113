import React, { useState, useContext, useEffect } from "react";
import logo from "../assets/img/imtewhite.png";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../UserContext";
import useAES from "../useAES";
import "../assets/css/nav.css";

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const { dataAES, setDataAES } = useContext(UserContext);
  let token =
    sessionStorage.getItem("token") || localStorage.getItem("token") || null;
  const { data } = useAES(token);
  useEffect(() => {
    if (token !== null) {
      setDataAES(data);
    }
  }, []);

  return (
    <>
      {/* {console.log(location)} */}
      {/* navbar */}
      <section
        className="gradblue nav-tetep"
        style={{
          color: "#ffffff",
          fontFamily: "gotham",
          backdropFilter: "blur(15px)",
        }}
      >
        <div className=" container">
          {/* mobile Navbar */}
          <div className={path === "/login" ? "d-block d-sm-none" : "d-none"}>
            <nav className="navbar navbar-default align-items-start">
              <div className="container">
                <div className="navbar-header">
                  <Link to={"/"} className="navbar-brand">
                    <img
                      src={logo}
                      style={{ width: "100px" }}
                      alt="IMTE Logo"
                      className="logo"
                    />
                  </Link>
                </div>
                <button type="button" className="btn">
                  <i className="bi bi-door-open"></i>
                </button>
              </div>
            </nav>
          </div>

          {/* web Navbar */}
          <div className={path !== "/login" ? "d-block" : "d-none d-sm-block"}>
            <nav className="navbar navbar-default">
              <div className="container">
                <div className="navbar-header">
                  <Link to={"/"} className="navbar-brand">
                    <img
                      src={logo}
                      style={{ width: "75px" }}
                      alt="IMTE Logo"
                      className="logo"
                    />
                  </Link>
                </div>

                <div className="navbar-nav navbar-right">
                  {sessionStorage.getItem("token") ||
                  localStorage.getItem("token") ? (
                    <div className="nav-item">
                      <div className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            setShowMenu(!showMenu);
                            // console.log(showMenu);
                          }}
                        >
                          <div
                            className="__profile_photo"
                            style={{
                              background: `url(../profile_pic/${
                                dataAES.token &&
                                dataAES.token.token.photo_location
                              }) center center /cover `,
                              display: "inline-block",
                            }}
                          ></div>
                          <strong style={{ margin: "15px" }}>
                            {dataAES.token && dataAES.token.token.first_name}
                          </strong>
                        </Link>
                      </div>
                      {showMenu === true ? (
                        <div
                          className="__dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{
                            display: showMenu === true ? "block" : "none",
                            color: "black",
                          }}
                          onMouseLeave={() => {
                            setShowMenu(false);
                          }}
                        >
                          <Link
                            to={"/d"}
                            className="dropdown-item"
                            onClick={() => {}}
                          >
                            <span className="bi bi-person"></span>
                            Profile
                          </Link>
                          <div className="dropdown-divider"></div>
                          <Link
                            to={"/"}
                            className="dropdown-item"
                            onClick={() => {
                              sessionStorage.clear();
                              localStorage.clear();
                              window.location.assign("/");
                            }}
                          >
                            <span className="bi bi-door-open"></span>
                            Log Out
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <Link className="nav-link" to={"/login"}>
                      <div
                        className="nav-item"
                        style={{ cursor: "pointer" }}
                        onMouseEnter={(e) =>
                          (e.target.children[0].className = "bi bi-door-open")
                        }
                        onMouseLeave={(e) =>
                          (e.target.children[0].className = "bi bi-door-closed")
                        }
                      >
                        <i
                          className="bi bi-door-closed"
                          style={{ margin: "0px 10px" }}
                        ></i>
                        Login
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default Nav;
