import React from "react";
import Enroll from "./enroll";
import Hero from "./hero";
import Galleries from "./galleries";
import create from "../assets/img/imgcreate.png";
import perform from "../assets/img/imgperform.png";
import respond from "../assets/img/imgrespond.png";
import icopiano from "../assets/img/icopiano.png";
import icodrum from "../assets/img/icodrum.png";
import icoguitar from "../assets/img/icoguitar.png";
import icovocal from "../assets/img/icovocal.png";
import icoviolin from "../assets/img/icoviolin.png";
import icoflute from "../assets/img/icoflute.png";
import icosaxo from "../assets/img/icosaxo.png";
import ErrorBoundary from "../errorBoundary";
import useFetch from "../useFetch";

const HomePage = () => {
  const { data, isLoading, error } = useFetch("major");

  return (
    <>
      <Hero />
      {isLoading && <div>Loading... </div>}
      {error && <div>{error}</div>}
      {/* Ads Text */}
      <section
        className="gradblue"
        style={{ textAlign: "center", color: "#ffffff" }}
      >
        <div className="container">
          <div className="w-100" style={{ height: "25px" }}></div>
          <div style={{ fontSize: "33px" }}>
            Challenge Your Musical Skill Here!
          </div>
          <div style={{ fontSize: "18px" }}>
            Creating | Performing | Responding | Instrument Knowledge
          </div>
          <div className="w-100" style={{ height: "25px" }}></div>
        </div>
        <div style={{ clear: "both" }}></div>
      </section>

      {/* 3 IMTE Point */}
      <section>
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row align-items-start justify-content-around">
            <div className="w-100" style={{ height: "50px" }}></div>
            <div className="col-md-3">
              <img
                src={create}
                style={{ width: "80%" }}
                alt="IMTE Creating"
                className="mmt"
              />
              <div className="w-100" style={{ height: "25px" }}></div>
              <h1 style={{ fontSize: "24px" }}>Creating</h1>
              <br />
              <p style={{ fontSize: "15px" }}>
                Students need to have experience in <strong>creating</strong>,
                to be successful musicians and to be successful 21st century
                citizens.
              </p>
            </div>
            <div className="col-md-3">
              <img
                src={perform}
                style={{ width: "80%" }}
                alt="IMTE Performing"
                className="mmt"
              />
              <div className="w-100" style={{ height: "25px" }}></div>
              <h1 style={{ fontSize: "24px" }}>Performing</h1>
              <br />
              <p style={{ fontSize: "15px" }}>
                Students need to <strong>perform</strong> – as singers, as
                instrumentalists, and in their lives and careers.
              </p>
            </div>
            <div className="col-md-3">
              <img
                src={respond}
                style={{ width: "80%" }}
                alt="IMTE Responding"
                className="mmt"
              />
              <div className="w-100" style={{ height: "25px" }}></div>
              <h1 style={{ fontSize: "24px" }}>Responding</h1>
              <br />
              <p style={{ fontSize: "15px" }}>
                Students need to <strong>respond</strong> to music, as well as
                to their culture, their community, and their colleagues.
              </p>
              <br />
              <p style={{ fontSize: "9px", lineHeight: "1.25" }}>
                (American Standard of Music Education - NAFME Inspired)
              </p>
            </div>
            <div className="w-100" style={{ height: "25px" }}></div>
          </div>
          <div className="row align-items-start justify-content-around">
            <div className="col-md-8">
              <div style={{ fontSize: "24px" }}>OPEN for INSTRUMENTS</div>
              <div style={{ fontSize: "18px" }}>
                {data && data.map((d) => `| ${d.major} `)} |
              </div>
            </div>
            <div className="w-100" style={{ height: "25px" }}></div>
          </div>
          <div className="row align-items-start justify-content-center">
            <div className="col-3 col-md-1">
              <img
                src={icopiano}
                style={{ width: "100%" }}
                alt="Piano Major"
                className="mmt"
              />
            </div>
            <div className="col-3 col-md-1">
              <img
                src={icodrum}
                style={{ width: "100%" }}
                alt="Drum Major"
                className="mmt"
              />
            </div>
            <div className="col-3 col-md-1">
              <img
                src={icoguitar}
                style={{ width: "100%" }}
                alt="Acoustic Guitar Major"
                className="mmt"
              />
            </div>
            <div className="col-3 col-md-1">
              <img
                src={icovocal}
                style={{ width: "100%" }}
                alt="Vocal Major"
                className="mmt"
              />
            </div>
            <div className="col-3 col-md-1">
              <img
                src={icoviolin}
                style={{ width: "100%" }}
                alt="Violin Major"
                className="mmt"
              />
            </div>
            <div className="col-3 col-md-1">
              <img
                src={icoflute}
                style={{ width: "100%" }}
                alt="Flute Major"
                className="mmt"
              />
            </div>
            <div className="col-3 col-md-1">
              <img
                src={icosaxo}
                style={{ width: "100%" }}
                alt="Saxophone Major"
                className="mmt"
              />
            </div>
            <div className="w-100" style={{ height: "50px" }}></div>
          </div>
        </div>
      </section>

      <Enroll />
      <ErrorBoundary>
        <Galleries
          userName="imte.education"
          className="Feed"
          classNameLoading="Loading"
          limit="6"
        />
      </ErrorBoundary>
    </>
  );
};

export default HomePage;
