import { useState, useEffect } from "react";
import API from "./api";

const usePost = (url, formbody) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCtr = new AbortController();
    setTimeout(() => {
      API.post(url, formbody)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          setData(data);
          setIsLoading(false);
          setError(null);
          // console.log(data);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.log("post data aborted");
          } else {
            setIsLoading(false);
            setError(err.message);
            // console.log(err.message);
          }
        });
    }, 0);
    return () => abortCtr.abort();
  }, [url]);

  return { data, isLoading, error };
};

export default usePost;
