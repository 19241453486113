import React from "react";
import { Link } from "react-router-dom";
import useWindow from "../useWindow";

const DashCourse = () => {
  const { width } = useWindow();

  return (
    <>
      <div className="w-100">
        <div
          className="navbar navbar-default align-items-start"
          style={{ margin: "0px", padding: "0px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className="navbar-header">
              <div className="page-title" style={{ color: "#212529" }}>
                COURSE
              </div>
            </div>
            {width > 450 ? (
              ""
            ) : (
              <Link
                to={"/"}
                className="nav-link"
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  window.location.assign("/");
                }}
              >
                <button type="button" className="btn btn-default btn-lg">
                  <i className="bi bi-door-open" aria-hidden="true"></i>
                </button>
              </Link>
            )}
          </div>
        </div>
        <hr />
        <div className="col-md-12">Under Development</div>
      </div>
    </>
  );
};

export default DashCourse;
