import React from "react";
import youtube from "../assets/img/iconyt.png";
import instagram from "../assets/img/iconig.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      {/* Footer */}
      <section className="" style={{ textAlign: "center", color: "#ffffff" }}>
        <div className="container">
          <div className="w-100" style={{ height: "50px" }}></div>

          <div style={{ fontSize: "21px", color: "rgb(225, 38, 39,1)" }}>
            <strong>IMTE HEAD OFFICE</strong>
          </div>

          <div style={{ fontSize: "12px", color: "rgba(65, 105, 178, 1)" }}>
            <strong>address.</strong> Puri Anjasmoro Blok E1, No.21, Semarang -
            Jawa Tengah 50144
            <br />
            <strong>email.</strong> imte.exam@gmail.com
            <br />
          </div>

          <div className="w-100" style={{ height: "25px" }}></div>

          <div
            style={{
              fontSize: "12px",
              color: "rgba(65, 105, 178, 1)",
              textDecoration: "none",
            }}
          >
            <Link to={"/"} className="">
              <img
                src={youtube}
                alt="IMTE youtube"
                style={{ padding: "7px" }}
              />
              IMTE Exam
            </Link>
            <a
              href="https://www.instagram.com/imte.education/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagram}
                alt="IMTE Instagram"
                style={{ padding: "7px" }}
              />
              @imte.education
            </a>
          </div>

          <div className="w-100" style={{ height: "25px" }}></div>

          <div style={{ fontSize: "9px", color: "rgba(65, 105, 178, 1)" }}>
            IMTE | International Music Technology Examination
          </div>

          <div className="w-100" style={{ height: "25px" }}></div>
        </div>
        <div style={{ clear: "both" }}></div>
      </section>
    </>
  );
}

export default Footer;
