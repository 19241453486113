import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import API from "../api";

const Otp = (props) => {
  const location = useLocation();
  const [data, setData] = useState({ timer: 0 });
  const [locate, setLocate] = useState("");

  useEffect(() => {
    let num = data.timer;
    let re = 0;
    setData({ ...data, timer: re });
    if (num !== 0) {
      setInterval(() => {
        re = num--;
      }, 1000);
    } else {
      // console.log(data.timer);
    }
  }, [data.timer]);
  let avpi = (
    sessionStorage.getItem("avpi") || localStorage.getItem("avpi")
  ).split(",");
  const handleChange = (e) => {
    // let nam = e.target.name;
    let val = e.target.value;
    // console.log(nam, val.length, location.state);
    if (isNaN(val)) {
      alert("must be a number");
    } else if (val.length === 6) {
      let ids = parseInt(avpi[3]);
      let formData = { id: ids, otp: val };
      API.post("user/verifyEmail", formData)
        .then((re) => {
          // console.log(re);
          alert(re.data.message);
          let store = `${avpi[0]},1,${avpi[2]},${avpi[3]}`;
          sessionStorage.removeItem("avpi");
          sessionStorage.setItem("avpi", store);
          localStorage.setItem("avpi", store);
          setLocate({ ...locate, verified: "1" });
        })
        .catch((er) => console.log(er));
    }
  };
  const handleResend = (e) => {
    console.log(location.state);
    // let num = parseInt(data.timer);
    let id = { id: parseInt(avpi[3]) };
    // setTimeout(() => {
    //   setData({ ...data, timer: 10 });
    // }, 500);
    API.post("user/sendVerifEmail", id)
      .then(async (re) => {
        // console.log(re);
        setData({ ...data, timer: 10, message: re.data.message });
      })
      .catch((er) => console.log(er));
  };
  const handleClose = (e) => {
    let store = `${avpi[0]},1,${avpi[2]},${avpi[3]}`;
    sessionStorage.removeItem("avpi");
    sessionStorage.setItem("avpi", store);
    localStorage.setItem("avpi", store);
    props.handleClose(locate);
  };
  return (
    <>
      <div
        className="col-md-12"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0",
          padding: "0",
        }}
      >
        <h1>Activate</h1>

        <div
          onMouseEnter={(e) =>
            (e.target.children[0].className = "bi bi-x-square-fill")
          }
          onMouseLeave={(e) =>
            (e.target.children[0].className = "bi bi-x-square")
          }
          onClick={handleClose}
        >
          <i className="bi bi-x-square"></i>
        </div>
      </div>
      <hr style={{ margin: "0" }} />
      <div className="w-100" style={{ height: "25px" }}></div>
      <div>
        <small>Please Input the OTP code sended to your E-Mail.</small>
        <input
          className="form-control"
          type="tel"
          name="otp"
          onChange={handleChange}
        />
        <hr />
        <button className="btn btn-warning" onClick={handleClose}>
          Verified Later
        </button>
        {data.timer === 0 ? (
          <button className="btn btn-primary" onClick={handleResend}>
            Resend OTP
          </button>
        ) : (
          <button className="btn btn-secondary">Resend OTP</button>
        )}

        <br />
        <span>
          {data.message &&
            data.message + " Please check your email for OTP Code."}
        </span>
      </div>
    </>
  );
};

export default Otp;
