import React from "react";
import useFetch from "../useFetch";
import EnrollUnit from "./enrollUnit";

const EnrollUnits = () => {
  const { data, isLoading, error } = useFetch("unit");

  return (
    <>
      <div className="row justify-content-center">
        {/* {console.log(data)} */}
        {isLoading && <div>Loading... </div>}
        {error && <div>{error}</div>}
        {data &&
          data
            .filter((f) => f.id !== 15)
            .map((d) => <EnrollUnit key={d.id} data={d} />)}
      </div>
    </>
  );
};
export default EnrollUnits;
