import React from "react";
import useWindow from "../useWindow";

const DashFoot = () => {
  const { width } = useWindow();
  return (
    <>
      {width > 450 ? (
        <div className="copyright">
          <div className="sub-copyright">
            <div style={{ clear: "both" }}></div>
            {/* <div className="w-100" style={{ height: "25px" }}></div> */}

            <div style={{ fontSize: "9px", color: "rgba(65, 105, 178, 1)" }}>
              IMTE | International Music Technology Examination
            </div>

            <div className="w-100" style={{ height: "25px" }}></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default DashFoot;
