import React from "react";
import { Routes, Route } from "react-router-dom";
import "../assets/css/dashNav.css";
import DashNav from "./dashNav";
import Dash from "./dash";
import DashCourse from "./dashCourse";
import Profile from "./profile";
import DashHis from "./dashHis";
import DashFoot from "./dashFoot";
import Page404 from "../site/Page404";

const Dashboard = () => {
  return (
    <>
      <div id="main" className="w-100">
        <DashNav />
        <Routes>
          <Route path={"/"} element={<Dash />} />
          <Route path={"/course"} element={<DashCourse />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/riwayat"} element={<DashHis />} />
          <Route path={"/*"} element={<Page404 />} />
        </Routes>
      </div>
      <DashFoot />
    </>
  );
};

export default Dashboard;
