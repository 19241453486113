import React from "react";
// import Login from "../site/login";
import Home from "../site/home";
import DashCourse from "../dashboard/dashCourse";
import Profile from "../dashboard/profile";
import DashHis from "../dashboard/dashHis";
import Dashboard from "../dashboard/dashboard";
import Dash from "../dashboard/dash";
import QRCam from "../qrcode/cam1";
// import Signup from "../signup1";
// import SignupForm from "../signupForm";
import Page404 from "../site/Page404";

const routes = {
  dash: [
    {
      path: "/d/profile",
      element: <Profile />,
    },
    {
      path: "/d/riwayat",
      element: <DashHis />,
    },
    {
      path: "/d/qrcam/:id",
      element: <QRCam />,
    },
    {
      path: "/d/qrcam",
      element: <QRCam />,
    },
    {
      path: "/d/course",
      element: <DashCourse />,
    },
    {
      path: "/d",
      element: <Dash />,
    },
  ],
  web: [
    {
      path: "/d/*",
      element: <Dashboard />,
    },
    {
      path: "/*",
      element: <Home />,
    },
    {
      path: "*",
      element: <Page404 />,
    },
  ],
};

export default routes;
