import React, { useState, useRef, useEffect } from "react";
import { showFormattedDate } from "../config/dateFormat";
import Modal from "../site/modal";

const News = (props) => {
  const [vis, setVis] = useState({ modal: false });
  const d = props.data;

  const myRefs = useRef();

  useEffect(() => {
    let coRef = myRefs.current;
    let content = d.content;
    content = content
      .split(" ")
      .slice(0, 10)
      .join(" ");
    coRef.innerHTML = content;
  }, []);

  return (
    <div key={d.id}>
      <Modal
        modal={vis.modal}
        title={d.title}
        element={<ModalDiv data={d} />}
        handleClose={() => setVis({ modal: false })}
      />
      <div className="row col-12" style={{ margin: "0px", padding: "0px" }}>
        <div
          className="row col-12"
          style={{
            margin: "0px",
            padding: "7px 0px",
            background: `white url(${d.filename}) no-repeat 90% center`,
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
            overflow: "hidden",
          }}
          onClick={(e) => setVis({ ...vis, modal: true })}
        >
          <div
            style={{
              background: `url(https://adm.imte.education/img/BlogImages/${d.filename}) no-repeat center center / cover`,
              width: "30%",
              height: "150px",
              display: "flex",
            }}
          ></div>
          <div
            style={{
              maxWidth: "70%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <b>{d.title}</b>
            <br />
            <div
              style={{ display: "flex", flexDirection: "row", color: "black" }}
            >
              {d.tags.split(",").map((a, i) => (
                <div
                  style={{
                    color: "white",
                    padding: "3px",
                  }}
                >
                  <div
                    key={i + "-" + d.title}
                    style={{
                      borderRadius: "3px",
                      backgroundColor: "gray",
                      fontSize: "10px",
                      padding: "5px",
                    }}
                  >
                    {a}
                  </div>
                </div>
              ))}
            </div>
            <div ref={myRefs}></div>
            <hr style={{ margin: 0, lineHeight: "1" }} />
            <div style={{ position: "relative" }}>
              <p>
                <small>
                  <i>{d.first_name}</i>
                </small>
              </p>
              <small
                style={{
                  fontSize: "10px",
                  color: "gray",
                  position: "absolute",
                  left: "0",
                  top: "7px",
                }}
              >
                <br /> <i> {showFormattedDate(d.created_at)}</i>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100" style={{ height: "25px" }}></div>
    </div>
  );
};

const ModalDiv = (props) => {
  const d = props.data;
  const myRefs = useRef();

  useEffect(() => {
    let coRef = myRefs.current;
    coRef.innerHTML = d.content;
  }, []);
  return (
    <>
      <img
        src={`https://adm.imte.education/img/BlogImages/${d.filename}`}
        alt="header"
        width="100%"
      />
      <div ref={myRefs}>{console.log(props)}</div>;
      <hr />
      <div style={{ display: "flex", flexDirection: "row", color: "black" }}>
        tags :{" "}
        {d.tags.split(",").map((a, i) => (
          <div
            style={{
              color: "white",
              padding: "3px",
            }}
          >
            <div
              key={i + "-" + d.title}
              style={{
                borderRadius: "3px",
                backgroundColor: "gray",
                fontSize: "10px",
                padding: "5px",
              }}
            >
              {a}
            </div>
          </div>
        ))}
      </div>
      <div style={{ position: "relative" }}>
        <p>
          <small>
            <i>{d.first_name + " " + d.last_name}</i>
          </small>
        </p>
        <small
          style={{
            fontSize: "10px",
            color: "gray",
            position: "absolute",
            left: "0",
            top: "7px",
          }}
        >
          <br /> <i> {showFormattedDate(d.created_at)}</i>
        </small>
      </div>
    </>
  );
};

export default News;
