import React, { useState } from "react";
import { Link } from "react-router-dom";
// import API from "../api";
// import QRCam from "../qrcode/cam";
// import QRGen from "../qrcode/gen";
// import QRScan from "../qrcode/scan";
import useAES from "../useAES";
import useFetch from "../useFetch";
import usePost from "../usePost";
import useWindow from "../useWindow";
import EnrollNow from "./enrollNow";
import { Icon } from "../config/icon";
import News from "./news";
import Modal from "../site/modal";

const Dash = () => {
  const [vis, setVis] = useState({ modal: false });
  const { data: unit } = useFetch("unit");
  const { width } = useWindow();
  let token = sessionStorage.getItem("token") || localStorage.getItem("token");
  const { data: aes } = useAES(token);
  const [modal, setModal] = useState({ vis: true });

  let avpi = (
    sessionStorage.getItem("avpi") || localStorage.getItem("avpi")
  ).split(",");
  let ids = parseInt(avpi[3]);

  const { data: enroll } = usePost("enroll/show", {
    tab_user_id: ids,
  });
  // const { data: act } = useFetch(`activity/getStatusFormat`);
  const { data: news } = useFetch("blog/showAll");

  return (
    <>
      <Modal
        modal={vis.modal}
        title={"Enroll Now"}
        element={
          <EnrollNow
            handleClose={(e) => {
              setVis({ ...vis, modal: false });
            }}
          />
        }
        handleClose={(e) => {
          setVis({ ...vis, modal: false });
        }}
      />
      {console.log(enroll)}
      {/* {modal.vis === true ? (
        ""
      ) : (
        <div className="__modal-window">
          <div className="col-md-7 col-11" style={{ borderRadius: "5px" }}>
            <div
              className="w-100 justify-content-around"
              style={{
                textAlign: "justify",
                height: "auto",
              }}
            >
              <EnrollNow
                handleClose={(e) => {
                  setVis({ ...vis, vis: false });
                }}
              />
            </div>
          </div>
        </div>
      )} */}
      <div className="w-100 ">
        <div
          className="navbar navbar-default align-items-start"
          style={{ margin: "0px", padding: "0px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className="navbar-header">
              <div className="page-title" style={{ color: "#212529" }}>
                DASHBOARD
              </div>
            </div>
            {width > 450 ? (
              ""
            ) : (
              <Link
                to={"/"}
                className="nav-link"
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                }}
              >
                <button type="button" className="btn btn-default btn-lg">
                  <i className="bi bi-door-open" aria-hidden="true"></i>
                </button>
              </Link>
            )}
          </div>
        </div>
        <hr />
        {/* {JSON.stringify(`${height} x ${width}`)} */}
        <div
          className="row col-md-12"
          style={{ margin: "0px", padding: "15px" }}
        >
          <div className="col-md-6">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              My Booking
              {avpi && avpi[0] === "active" ? (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => setVis({ ...vis, modal: true })}
                >
                  + Book Now
                </button>
              ) : (
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => alert("IMTE registration are not active.")}
                >
                  + Book Now
                </button>
              )}
            </div>
            <hr />

            {enroll &&
              enroll
                .filter((d) => d.active === true)
                .map((d, i) => (
                  <div key={i}>
                    <div
                      className="row col-12"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <div
                        className="row col-12"
                        style={{
                          margin: "0px",
                          padding: "7px 0px",
                          background: `white url(${
                            Icon[d.major.major.toLowerCase()]
                          }) no-repeat 90% center`,
                          borderRadius: "5px",
                        }}
                      >
                        <b>
                          {d.major.major} - {d.grade.grade}
                        </b>
                        <br />
                        <small>
                          <i>{d.period.period_name}</i>
                        </small>
                      </div>
                      {/* <div
                        className="row col-6 align-item-center justify-content-around"
                        style={{
                          margin: "0px",
                          padding: "0px",
                          textAlign: "center",
                        }}
                      >
                        {act &&
                          act.map((c, i) => (
                            <div
                              key={`${d.id}-${i}-${c}`}
                              className="col"
                              style={{
                                padding: "0px",
                              }}
                            >
                              <small>{c}</small>
                            </div>
                          ))}
                        <div className="w-100"></div>
                        {d.activity_status.split("").map((c, i) => (
                          <div
                            key={`${d.id}-${i}`}
                            className="col"
                            style={{
                              padding: "0px",
                            }}
                          >
                            <i
                              className={
                                {
                                  0: "bi bi-square",
                                  1: "bi bi-check2-square",
                                }[c]
                              }
                              style={{
                                color: {
                                  0: "grey",
                                  1: "green",
                                }[c],
                                margin: "0",
                              }}
                            ></i>
                          </div>
                        ))}
                      </div> */}
                    </div>
                    <div className="w-100" style={{ height: "25px" }}></div>
                  </div>
                ))}
            {/* {console.log(unit)} */}
            {avpi && avpi[0] === "active" ? (
              <div style={{ fontSize: "10px" }}>
                <b>Note:</b>
                <br />
                Exam will be held on
                {unit &&
                  unit
                    .filter((d) => d.id === aes.token.token.tab_unit_id)
                    .map((d, i) => (
                      <>
                        {" "}
                        {d.mulai} - {d.selesai} for unit {d.unit_name}.
                      </>
                    ))}
                {/* {JSON.stringify(unit)} */}
              </div>
            ) : (
              ""
            )}
            <div className="w-100" style={{ height: "50px" }}></div>
          </div>
          {/* <div className="col-md-4">
            Activity
            <hr />
            <QRScan />
          </div> */}
          <div
            className="col-md-6"
            style={{ overflowY: "auto", height: "85vh" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Latest News
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setModal({ ...modal, vis: false })}
                style={{ visibility: "hidden" }}
              >
                + News
              </button>
            </div>
            <hr />
            {console.log(news)}
            {news && news.length > 0
              ? news.map((d) => <News key={d.id} data={d} />)
              : "No Updated News"}
            {/* No Updated News */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dash;
