import React from "react";

const Page404 = () => {
  return (
    <>
      <div
        className=""
        style={{
          minHeight: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          background:
            "rgba(0, 0, 0, 0) url('https://images.pexels.com/photos/6991503/pexels-photo-6991503.jpeg?auto=compress&cs=tinysrgb&w=1920&h=2880&dpr=1') no-repeat 0% 60%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "72px",
              display: "flex",
              alignItems: "center",
              padding: "30px",
              minHeight: "120px",
            }}
          >
            404
          </div>
          <div
            style={{
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              padding: "30px",
              minHeight: "120px",
              borderLeft: "solid 1px gray",
            }}
          >
            <div>
              Congratulation..
              <br />
              You found our 404 Pages.
              <br />
              <small style={{ color: "gray" }}>
                We think something is broken and we will get it repaired ASAP
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
