import { useState, useEffect, useContext } from "react";
import CryptoJS from "crypto-js";
// import API from "./api";
import { UserContext } from "./UserContext";
import theVariable from "./config/theVariable";
import useDate from "./useDate";

const useAES = (token) => {
  const { dataAES, setDataAES } = useContext(UserContext);
  const { DD, MM, YY } = useDate();
  const [data, setData] = useState("");
  const date = YY + "-" + MM + "-" + DD;

  useEffect(() => {
    setTimeout(async () => {
      function aesDe(token) {
        if (token === null) {
          return null;
        } else {
          let bytes = CryptoJS.AES.decrypt(token, theVariable.AESEncryption);
          let dt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          console.log(dt);

          // cek token expiry date
          let exp =
            new Date(date).getDate() - new Date(dt.token.date).getDate();

          // delete token if expired
          if (exp >= 3) {
            localStorage.clear();
          } else {
            setData(dt);
            setDataAES(dt);
          }
        }
      }

      return dataAES === null ? "" : aesDe(token);
    }, 0);
  }, []);
  return { data };
};

export default useAES;
