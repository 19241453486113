import icoPiano from "../assets/img/icopiano.png";
import icoDrum from "../assets/img/icodrum.png";
import icoGuitar from "../assets/img/icoguitar.png";
import icoVocal from "../assets/img/icovocal.png";
import icoViolin from "../assets/img/icoviolin.png";
import icoFlute from "../assets/img/icoflute.png";
import icoSaxo from "../assets/img/icosaxo.png";

export const Icon = {
  piano: icoPiano,
  drum: icoDrum,
  "acoustic guitar": icoGuitar,
  " electric guitar": icoGuitar,
  vocal: icoVocal,
  violin: icoViolin,
  flute: icoFlute,
  saxophone: icoSaxo,
  bass: icoGuitar,
};
