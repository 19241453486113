import React from "react";
import { Routes, Route } from "react-router-dom";
import Nav from "./nav";
import Footer from "./footer";
import Login from "./login";
import Page404 from "./Page404";
import HomePage from "./homePage";

const Home = () => {
  return (
    <>
      <Nav />
      <div className="w-100" style={{ height: "75px" }}></div>
      <Routes>
        <Route exact path={"/"} element={<HomePage />} />
        <Route exact path={"/login"} element={<Login />} />
        <Route path={"/*"} element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Home;
