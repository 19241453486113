import React from "react";

const Instrument = (props) => {
  // console.log(props);

  return (
    <div className="" style={{ display: "block" }}>
      <input
        className="m-1"
        type="radio"
        id={props.id}
        name={props.name}
        value={props.id}
        onChange={props.onChange}
        checked={props.checked}
      />
      <label
        className="m-1 form-check-label"
        htmlFor={props.value}
        style={{ textTransform: "uppercase" }}
      >
        {props.value}
      </label>
    </div>
  );
};

export default Instrument;
